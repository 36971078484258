<template>
  <div>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="text-left shadow-sm"
      flat
      height="80"
    >
    <v-row>
      <v-col class="pl-lg-16 header-logo col-md-6 col-8">
        <v-img src="@/assets/images/headerlogo.png" class="flex-grow-0" width="250" height="80" contain/>
      </v-col>
      <!-- <v-col class="col-8 col-lg-4">
        <v-row v-if="themeApiModel.show_phone === 'On' || !themeApiModel" class="pa-0 ma-0 h-full" align="center" justify="center">
          <div class="font-weight-bold text-h6 text-center">
            <span class="primary--text">Call Us On:</span> <br class="d-block d-lg-none"/><span class ="secondary--text">1300 777 899</span>
          </div>
        </v-row>
      </v-col> -->
      <v-col cols="4" class="d-none d-lg-block">
      </v-col>
    </v-row>
    </v-app-bar>
    <div class="jarallax home-jarallax  d-sm-block d-none">
      <img class="jarallax-img" alt=""
        :src="themeModel && themeModel.donate_bg_image ? themeModel.donate_bg_image : bg_default_img"/>
    </div>
    <div class="jarallax home-jarallax d-sm-none d-block">
      <img class="jarallax-img" alt=""
        :src="themeModel && themeModel.donate_bg_mobile_image ? themeModel.donate_bg_mobile_image : mobile_default_img"/>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { jarallax } from "jarallax";

export default {
  name: "HeaderLayout",
  computed: {
    ...mapGetters(["getThemeMode", "themeApiModel"]),
  },
  data() {
    return {
      user: null,
      themeModel: {},
      bg_default_img: "@/assets/images/Donate-Page-Background-Image.jpg",
      mobile_default_img: "@/assets/images/Mobile-View-Bg-Image.jpeg"
    };
  },
  watch: {
    themeApiModel: function(newValue) {
      this.themeModel = newValue;
    }
  },
  created () {
    this.themeModel = this.themeApiModel;
    this.user = this.$helpers.getCurData('curUser');
  },
  mounted() {
    jarallax(document.querySelectorAll(".jarallax"));

    jarallax(document.querySelectorAll(".jarallax-keep-img"), {
      keepImg: true,
    });
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      ,
      "changeVerticalSidebarMini",
      "signOut",
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();
    },
    logoutUser() {
      this.$helpers.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss">
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
